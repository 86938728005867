import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../../components/RawHTML';
import Container from '../../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> e_ptp_default</div>
<div class="difference"><span class="status removed">Removed</span> e_ptp_none</div>
<div class="difference"><span class="status removed">Removed</span> e_pthttp</div>
<div class="difference"><span class="status removed">Removed</span> e_ptsocks5</div>
<div class="difference"><span class="status removed">Removed</span> PTProxyType</div>
<div class="difference"><span class="status removed">Removed</span> e_ptabort</div>
<div class="difference"><span class="status removed">Removed</span> e_ptskip</div>
<div class="difference"><span class="status removed">Removed</span> e_ptignore</div>
<div class="difference"><span class="status removed">Removed</span> PTErrorHandling</div>
<div class="difference"><span class="status removed">Removed</span> -[PTSDFDocSnapshot init]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTSDFResultSnapshot init]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTSDFUndoManager init]</div>
<div class="difference"><span class="status removed">Removed</span> PTHTML2PDF</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF getCptr]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF initWithCptr:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF setSwigCMemOwn:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF dealloc]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF InsertFromURL:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF InsertFromURLWithSettings:settings:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF InsertFromHtmlString:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF InsertFromHtmlStringWithSettings:settings:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF InsertTOC]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF InsertTOCWithSettings:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF Convert:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF GetHTTPErrorCode]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF SetQuiet:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF GetLog]</div>
<div class="difference"><span class="status removed">Removed</span> +[PTHTML2PDF SetModulePath:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF SetPaperSize:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF SetPaperSizeWithWidthAndHeight:height:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF SetLandscape:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF SetDPI:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF SetOutline:depth:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF DumpOutline:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF SetPDFCompression:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF SetMargins:bottom:left:right:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF SetImageDPI:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF SetImageQuality:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF SetCookieJar:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF Destroy]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTHTML2PDF init]</div>
<div class="difference"><span class="status removed">Removed</span> PTProxy</div>
<div class="difference"><span class="status removed">Removed</span> -[PTProxy getCptr]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTProxy initWithCptr:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTProxy setSwigCMemOwn:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTProxy dealloc]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTProxy SetType:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTProxy SetHost:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTProxy SetPort:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTProxy SetUsername:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTProxy SetPassword:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTProxy Destroy]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTProxy init]</div>
<div class="difference"><span class="status removed">Removed</span> PTWebPageSettings</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings getCptr]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings initWithCptr:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings setSwigCMemOwn:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings dealloc]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetPrintBackground:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetLoadImages:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetAllowJavaScript:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetSmartShrinking:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetMinimumFontSize:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetDefaultEncoding:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetUserStyleSheet:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetAllowPlugins:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetPrintMediaType:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetIncludeInOutline:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetUsername:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetPassword:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetJavaScriptDelay:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetZoom:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetBlockLocalFileAccess:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetStopSlowScripts:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetDebugJavaScriptOutput:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetLoadErrorHandling:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetExternalLinks:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetInternalLinks:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetProduceForms:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings SetProxy:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings Destroy]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings init]</div>
<div class="difference"><span class="status removed">Removed</span> PTTOCSettings</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTOCSettings getCptr]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTOCSettings initWithCptr:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTOCSettings setSwigCMemOwn:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTOCSettings dealloc]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTOCSettings SetDottedLines:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTOCSettings SetLinks:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTOCSettings SetCaptionText:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTOCSettings SetLevelIndentation:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTOCSettings SetTextSizeShrink:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTOCSettings SetXsl:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTOCSettings Destroy]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTOCSettings init]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> e_ptsha1</div>
<div class="difference"><span class="status added">Added</span> e_ptsha256</div>
<div class="difference"><span class="status added">Added</span> e_ptsha384</div>
<div class="difference"><span class="status added">Added</span> e_ptsha512</div>
<div class="difference"><span class="status added">Added</span> e_ptripemd160</div>
<div class="difference"><span class="status added">Added</span> e_ptunknown_digest_algorithm</div>
<div class="difference"><span class="status added">Added</span> PTDigestAlgorithmType</div>
<div class="difference"><span class="status added">Added</span> e_ptform_filled</div>
<div class="difference"><span class="status added">Added</span> e_ptdigital_signature_signed</div>
<div class="difference"><span class="status added">Added</span> e_ptpage_template_instantiated</div>
<div class="difference"><span class="status added">Added</span> e_ptannotation_created_or_updated_or_deleted</div>
<div class="difference"><span class="status added">Added</span> e_ptother</div>
<div class="difference"><span class="status added">Added</span> e_ptdct_unknown</div>
<div class="difference"><span class="status added">Added</span> PTDisallowedChangeType</div>
<div class="difference"><span class="status added">Added</span> e_ptcompatibility_and_archiving</div>
<div class="difference"><span class="status added">Added</span> e_ptmaximum</div>
<div class="difference"><span class="status added">Added</span> PTVerificationOptionsSecurityLevel</div>
<div class="difference"><span class="status added">Added</span> e_ptsigning</div>
<div class="difference"><span class="status added">Added</span> e_pttimestamp</div>
<div class="difference"><span class="status added">Added</span> e_ptcurrent</div>
<div class="difference"><span class="status added">Added</span> PTVerificationOptionsTimeMode</div>
<div class="difference"><span class="status added">Added</span> e_ptno_error</div>
<div class="difference"><span class="status added">Added</span> e_ptcorrupt_file</div>
<div class="difference"><span class="status added">Added</span> e_ptunsigned</div>
<div class="difference"><span class="status added">Added</span> e_ptbad_byteranges</div>
<div class="difference"><span class="status added">Added</span> e_ptcorrupt_cryptographic_contents</div>
<div class="difference"><span class="status added">Added</span> PTVerificationResultDocumentStatus</div>
<div class="difference"><span class="status added">Added</span> e_ptdigest_invalid</div>
<div class="difference"><span class="status added">Added</span> e_ptdigest_verified</div>
<div class="difference"><span class="status added">Added</span> e_ptdigest_verification_disabled</div>
<div class="difference"><span class="status added">Added</span> e_ptweak_digest_algorithm_but_digest_verifiable</div>
<div class="difference"><span class="status added">Added</span> e_ptno_digest_status</div>
<div class="difference"><span class="status added">Added</span> e_ptunsupported_encoding</div>
<div class="difference"><span class="status added">Added</span> PTVerificationResultDigestStatus</div>
<div class="difference"><span class="status added">Added</span> e_pttrust_verified</div>
<div class="difference"><span class="status added">Added</span> e_ptuntrusted</div>
<div class="difference"><span class="status added">Added</span> e_pttrust_verification_disabled</div>
<div class="difference"><span class="status added">Added</span> e_ptno_trust_status</div>
<div class="difference"><span class="status added">Added</span> PTVerificationResultTrustStatus</div>
<div class="difference"><span class="status added">Added</span> e_ptinvalidated_by_disallowed_changes</div>
<div class="difference"><span class="status added">Added</span> e_pthas_allowed_changes</div>
<div class="difference"><span class="status added">Added</span> e_ptunmodified</div>
<div class="difference"><span class="status added">Added</span> e_ptpermissions_verification_disabled</div>
<div class="difference"><span class="status added">Added</span> e_ptno_permissions_status</div>
<div class="difference"><span class="status added">Added</span> PTVerificationResultModificationPermissionsStatus</div>
<div class="difference"><span class="status added">Added</span> PTMemoryFilter</div>
<div class="difference"><span class="status added">Added</span> -[PTMemoryFilter getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTMemoryFilter initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTMemoryFilter setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTMemoryFilter dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTMemoryFilter GetBuffer]</div>
<div class="difference"><span class="status added">Added</span> -[PTMemoryFilter SetAsInputFilter]</div>
<div class="difference"><span class="status added">Added</span> -[PTMemoryFilter initWithBuf_sz:is_input:]</div>
<div class="difference"><span class="status added">Added</span> -[PTHTTPRequestOptions SkipByteRangeTest:]</div>
<div class="difference"><span class="status added">Added</span> -[PTHTTPRequestOptions UseRemoteFileSize:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFDocSnapshot initWithImpl:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFResultSnapshot initWithImpl:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFUndoManager initWithImpl:]</div>
<div class="difference"><span class="status added">Added</span> PTPDFRectCollection</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFRectCollection getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFRectCollection initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFRectCollection setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFRectCollection dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFRectCollection AddRect:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFRectCollection GetRectAt:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFRectCollection GetNumRects]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFRectCollection Clear]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFRectCollection init]</div>
<div class="difference"><span class="status added">Added</span> PTDigestAlgorithm</div>
<div class="difference"><span class="status added">Added</span> -[PTDigestAlgorithm getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigestAlgorithm initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigestAlgorithm setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigestAlgorithm dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigestAlgorithm init]</div>
<div class="difference"><span class="status added">Added</span> PTDisallowedChange</div>
<div class="difference"><span class="status added">Added</span> -[PTDisallowedChange getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTDisallowedChange initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDisallowedChange setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDisallowedChange dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTDisallowedChange Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTDisallowedChange GetObjNum]</div>
<div class="difference"><span class="status added">Added</span> -[PTDisallowedChange GetType]</div>
<div class="difference"><span class="status added">Added</span> -[PTDisallowedChange GetTypeAsString]</div>
<div class="difference"><span class="status added">Added</span> -[PTDisallowedChange init]</div>
<div class="difference"><span class="status added">Added</span> PTVerificationOptions</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions AddTrustedCertificate:]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions RemoveTrustedCertificate:]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions EnableModificationVerification:]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions EnableDigestVerification:]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions EnableTrustVerification:]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions initWithImpl:]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions initWithLevel:]</div>
<div class="difference"><span class="status added">Added</span> PTTrustVerificationResult</div>
<div class="difference"><span class="status added">Added</span> -[PTTrustVerificationResult getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTTrustVerificationResult initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTrustVerificationResult setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTrustVerificationResult dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTTrustVerificationResult Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTTrustVerificationResult WasSuccessful]</div>
<div class="difference"><span class="status added">Added</span> -[PTTrustVerificationResult GetResultString]</div>
<div class="difference"><span class="status added">Added</span> -[PTTrustVerificationResult GetTimeOfTrustVerification]</div>
<div class="difference"><span class="status added">Added</span> -[PTTrustVerificationResult GetTimeOfTrustVerificationEnum]</div>
<div class="difference"><span class="status added">Added</span> -[PTTrustVerificationResult initWithImpl:]</div>
<div class="difference"><span class="status added">Added</span> PTVerificationResult</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult GetVerificationStatus]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult GetDocumentStatus]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult GetDigestStatus]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult GetTrustStatus]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult GetPermissionsStatus]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult HasTrustVerificationResult]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult GetTrustVerificationResult]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult GetDisallowedChanges]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult GetSignersDigestAlgorithm]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult initWithImpl:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField Verify:]</div>
<div class="difference"><span class="status added">Added</span> -[PTInk GetHighlightIntent]</div>
<div class="difference"><span class="status added">Added</span> -[PTInk SetHighlightIntent:]</div>
<div class="difference"><span class="status added">Added</span> PTOCROptions</div>
<div class="difference"><span class="status added">Added</span> -[PTOCROptions getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTOCROptions initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOCROptions setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOCROptions dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTOCROptions AddIgnoreZonesForPage:page_num:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOCROptions AddLang:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOCROptions AddTextZonesForPage:page_num:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOCROptions AddDPI:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOCROptions init]</div>
<div class="difference"><span class="status added">Added</span> -[PTElementWriter WriteGStateChanges:]</div>
<div class="difference"><span class="status added">Added</span> PTViewerOptimizedOptions</div>
<div class="difference"><span class="status added">Added</span> -[PTViewerOptimizedOptions getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTViewerOptimizedOptions initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTViewerOptimizedOptions setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTViewerOptimizedOptions dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTViewerOptimizedOptions SetThumbnailRenderingThreshold:]</div>
<div class="difference"><span class="status added">Added</span> -[PTViewerOptimizedOptions SetThumbnailSize:]</div>
<div class="difference"><span class="status added">Added</span> -[PTViewerOptimizedOptions SetOverprint:]</div>
<div class="difference"><span class="status added">Added</span> -[PTViewerOptimizedOptions init]</div>
<div class="difference"><span class="status added">Added</span> +[PTPDFNet GetSystemFontList]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFView SetRequiredFieldBorderColor:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[TRN_date setMp_obj:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)setMp_obj:(SWIGTYPE_p_TRN_obj_ *)value</td></tr>
<tr><th>To</th><td class="declaration">- (void)setMp_obj:(void *)value</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[TRN_date getMp_obj]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (SWIGTYPE_p_TRN_obj_ *)getMp_obj</td></tr>
<tr><th>To</th><td class="declaration">- (void *)getMp_obj</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTSecurityHandler Clone:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTSecurityHandler *)Clone:(SWIGTYPE_TRN_SecurityHandler *)base</td></tr>
<tr><th>To</th><td class="declaration">- (PTSecurityHandler *)Clone:(void *)base</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[PTOCRModule ImageToPDF:src:options:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (void)ImageToPDF:(PTPDFDoc *)dst src:(NSString *)src options:(SWIGTYPE_p_OCROptions *)options</td></tr>
<tr><th>To</th><td class="declaration">+ (void)ImageToPDF:(PTPDFDoc *)dst src:(NSString *)src options:(PTOCROptions *)options</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[PTOCRModule ProcessPDF:options:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (void)ProcessPDF:(PTPDFDoc *)dst options:(SWIGTYPE_p_OCROptions *)options</td></tr>
<tr><th>To</th><td class="declaration">+ (void)ProcessPDF:(PTPDFDoc *)dst options:(PTOCROptions *)options</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[PTOCRModule GetOCRJsonFromImage:src:options:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (NSString *)GetOCRJsonFromImage:(PTPDFDoc *)dst src:(NSString *)src options:(SWIGTYPE_p_OCROptions *)options</td></tr>
<tr><th>To</th><td class="declaration">+ (NSString *)GetOCRJsonFromImage:(PTPDFDoc *)dst src:(NSString *)src options:(PTOCROptions *)options</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[PTOCRModule GetOCRJsonFromPDF:options:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (NSString *)GetOCRJsonFromPDF:(PTPDFDoc *)src options:(SWIGTYPE_p_OCROptions *)options</td></tr>
<tr><th>To</th><td class="declaration">+ (NSString *)GetOCRJsonFromPDF:(PTPDFDoc *)src options:(PTOCROptions *)options</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[PTOCRModule GetOCRXmlFromImage:src:options:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (NSString *)GetOCRXmlFromImage:(PTPDFDoc *)dst src:(NSString *)src options:(SWIGTYPE_p_OCROptions *)options</td></tr>
<tr><th>To</th><td class="declaration">+ (NSString *)GetOCRXmlFromImage:(PTPDFDoc *)dst src:(NSString *)src options:(PTOCROptions *)options</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[PTOCRModule GetOCRXmlFromPDF:options:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (NSString *)GetOCRXmlFromPDF:(PTPDFDoc *)src options:(SWIGTYPE_p_OCROptions *)options</td></tr>
<tr><th>To</th><td class="declaration">+ (NSString *)GetOCRXmlFromPDF:(PTPDFDoc *)src options:(PTOCROptions *)options</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTRadioButtonGroup initWithImpl:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (instancetype)initWithImpl:(SWIGTYPE_TRN_RadioButtonGroup *)impl</td></tr>
<tr><th>To</th><td class="declaration">- (instancetype)initWithImpl:(void *)impl</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTCallback PartDownloadedProc:doc:pageNum:objNum:message:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)PartDownloadedProc:(int)dlType doc:(SWIGTYPE_TRN_PDFDoc *)doc pageNum:(unsigned int)pageNum objNum:(unsigned int)objNum message:(NSString *)message</td></tr>
<tr><th>To</th><td class="declaration">- (void)PartDownloadedProc:(int)dlType doc:(void *)doc pageNum:(unsigned int)pageNum objNum:(unsigned int)objNum message:(NSString *)message</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[PTCallback StaticPartDownloadedProc:doc:pageNum:objNum:message:customData:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (void)StaticPartDownloadedProc:(int)dlType doc:(SWIGTYPE_TRN_PDFDoc *)doc pageNum:(unsigned int)pageNum objNum:(unsigned int)objNum message:(NSString *)message customData:(void *)customData</td></tr>
<tr><th>To</th><td class="declaration">+ (void)StaticPartDownloadedProc:(int)dlType doc:(void *)doc pageNum:(unsigned int)pageNum objNum:(unsigned int)objNum message:(NSString *)message customData:(void *)customData</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFRasterizer RasterizeSeparations:width:height:mtx:clip:cancel:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (NSMutableArray *)RasterizeSeparations:(PTPage *)page width:(int)width height:(int)height mtx:(PTMatrix2D *)mtx clip:(PTPDFRect *)clip cancel:(SWIGTYPE_p_bool *)cancel</td></tr>
<tr><th>To</th><td class="declaration">- (NSMutableArray *)RasterizeSeparations:(PTPage *)page width:(int)width height:(int)height mtx:(PTMatrix2D *)mtx clip:(PTPDFRect *)clip cancel:(BOOL *)cancel</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFDoc SaveViewerOptimized:opts:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)SaveViewerOptimized:(NSString *)path opts:(SWIGTYPE_p_ViewerOptimizedOptions *)opts</td></tr>
<tr><th>To</th><td class="declaration">- (void)SaveViewerOptimized:(NSString *)path opts:(PTViewerOptimizedOptions *)opts</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTXPSOutputCommonOptions setM_obj:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)setM_obj:(SWIGTYPE_p_TRN_obj_ *)value</td></tr>
<tr><th>To</th><td class="declaration">- (void)setM_obj:(void *)value</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTXPSOutputCommonOptions getM_obj]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (SWIGTYPE_p_TRN_obj_ *)getM_obj</td></tr>
<tr><th>To</th><td class="declaration">- (void *)getM_obj</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTSVGOutputOptions SetOverprint:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)SetOverprint:(SWIGTYPE_PDFRasterizer__OverprintPreviewMode *)mode</td></tr>
<tr><th>To</th><td class="declaration">- (void)SetOverprint:(PTOverprintPreviewMode)mode</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTTiffOutputOptions SetOverprint:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)SetOverprint:(int)mode</td></tr>
<tr><th>To</th><td class="declaration">- (void)SetOverprint:(PTOverprintPreviewMode)mode</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFView DownloaderUpdatePage:page_num:obj_num:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)DownloaderUpdatePage:(SWIGTYPE_TRN_PDFDoc *)doc page_num:(unsigned int)page_num obj_num:(unsigned int)obj_num</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)DownloaderUpdatePage:(void *)doc page_num:(unsigned int)page_num obj_num:(unsigned int)obj_num</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFView DownloaderUpdateOutline:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)DownloaderUpdateOutline:(SWIGTYPE_TRN_PDFDoc *)doc</td></tr>
<tr><th>To</th><td class="declaration">- (void)DownloaderUpdateOutline:(void *)doc</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFView DownloaderUpdateThumb:page_num:obj_num:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)DownloaderUpdateThumb:(SWIGTYPE_TRN_PDFDoc *)doc page_num:(unsigned int)page_num obj_num:(unsigned int)obj_num</td></tr>
<tr><th>To</th><td class="declaration">- (void)DownloaderUpdateThumb:(void *)doc page_num:(unsigned int)page_num obj_num:(unsigned int)obj_num</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFView DownloaderFinishedDownload:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)DownloaderFinishedDownload:(SWIGTYPE_TRN_PDFDoc *)doc</td></tr>
<tr><th>To</th><td class="declaration">- (void)DownloaderFinishedDownload:(void *)doc</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFView DownloaderIsCorrectDoc:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)DownloaderIsCorrectDoc:(SWIGTYPE_TRN_PDFDoc *)doc</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)DownloaderIsCorrectDoc:(void *)doc</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFView DownloaderInitialized:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)DownloaderInitialized:(SWIGTYPE_TRN_PDFDoc *)doc</td></tr>
<tr><th>To</th><td class="declaration">- (void)DownloaderInitialized:(void *)doc</td></tr>
</table>
<br>
</div>

</div>



<div class="headerFile">
<div class="headerName">Tools/AnnotTypes.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeFreehandHighlight</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameFreehandHighlight</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotationToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonFreehandHighlight</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDigitalSignatureTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDigitalSignatureTool.showsSavedSignatures</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTDigitalSignatureTool.showDefaultSignature</div>
<table>
<tr><th></th><th>Availability</th><th>Deprecation Message</th></tr>
<tr><th>From</th><td>Available</td><td><em>none</em></td></tr>
<tr><th>To</th><td>Deprecated</td><td>Deprecated in Apryse for iOS 7.1.0. Use showsSavedSignatures</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFloatingSigViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTFloatingSigViewController.saveSignatureForReuse</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFreeHandHighlightCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTFreeHandHighlightCreate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSavedSignaturesViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTSavedSignaturesViewControllerDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTSavedSignaturesViewControllerDelegate savedSignaturesControllerNewSignature:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSavedSignaturesViewControllerDelegate savedSignaturesControllerOneTimeSignature:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSavedSignaturesViewControllerDelegate savedSignaturesController:addSignature:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSavedSignaturesViewControllerDelegate savedSignaturesControllerWasDismissed:]</div>
<div class="difference"><span class="status added">Added</span> PTSavedSignaturesViewController</div>
<div class="difference"><span class="status added">Added</span> PTSavedSignaturesViewController.signaturesManager</div>
<div class="difference"><span class="status added">Added</span> PTSavedSignaturesViewController.delegate</div>
<div class="difference"><span class="status added">Added</span> -[PTSavedSignaturesViewController initWithSignaturesManager:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSignaturesManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTSignaturesManager</div>
<div class="difference"><span class="status added">Added</span> -[PTSignaturesManager numberOfSavedSignatures]</div>
<div class="difference"><span class="status added">Added</span> -[PTSignaturesManager savedSignatureAtIndex:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSignaturesManager imageOfSavedSignatureAtIndex:height:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSignaturesManager deleteSignatureAtIndex:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSignaturesManager moveSignatureAtIndex:toIndex:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSignaturesManager createSignature:withStrokeColor:withStrokeThickness:withinRect:saveSignature:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTStampManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTStampManager</div>
<table>
<tr><th></th><th>Availability</th><th>Deprecation Message</th></tr>
<tr><th>From</th><td>Available</td><td><em>none</em></td></tr>
<tr><th>To</th><td>Deprecated</td><td>Deprecated in Apryse for iOS 7.1.0. use PTSignatureManager</td></tr>
</table>
<br>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}